<template>
  <div id="no-encontrado" class="row center-xs">
    <div class="col-xs-12">
      <h1>404</h1>
    </div>
    <div class="col-xs-12">
      <h2>Página no encontrada</h2>
    </div>
    <div class="col-xs-12">
      <img class="logo" src="../assets/images/aguila.png" alt="">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
#no-encontrado {
  margin: 0;
}

#no-encontrado h1, h2 {
  font-family: Roboto, sans-serif;
  margin: 0;
}

#no-encontrado h1 {
  font-size: 20vw;
  opacity: 0.3;
}

#no-encontrado h2 {
  font-size: 4vw;
}

#no-encontrado img {
  margin-top: 5vh;
  height: 25vh;
}

</style>