<template>
  <div id="admin-reactivos">
    reactivos
  </div>
</template>

<script>

export default {
  name: 'AdminReactivos'
}

</script>

<style>



</style>