<template>
  <div id="admin-categorias">
    categorias
  </div>
</template>

<script>

export default {
  name: 'AdminCategorias'
}

</script>

<style>



</style>