import { render, staticRenderFns } from "./GraficaPastel.vue?vue&type=template&id=fd501aea&scoped=true&"
import script from "./GraficaPastel.vue?vue&type=script&lang=js&"
export * from "./GraficaPastel.vue?vue&type=script&lang=js&"
import style0 from "./GraficaPastel.vue?vue&type=style&index=0&id=fd501aea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd501aea",
  null
  
)

export default component.exports